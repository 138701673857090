import React, { useMemo } from "react"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { hexToRgb } from "@material-ui/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const colors = ["#64b5f6", "#ef9a9a", "#ce93d8", "#e6ee9c", "#9fa8da", "#ffd54f"]

export const options = {
    indexAxis: 'y',
    responsive: true,
    tooltips: {
        enabled: true
    },
    plugins: {
        datalabels: {
            formatter: (value, ctx) => {
              const datapoints = ctx.chart.data.datasets[0].data
               const total = datapoints.reduce((total, datapoint) => total + datapoint, 0)
              const percentage = value / total * 100
              return percentage.toFixed(2) + "%";
            },
            color: '#fff',
            font: {
                weight: 'bold',
            }
        },
        legend: {
        position: 'top',
        },
        title: {
        display: true,
        text: '',
        },
    },
};

const hexToRgbA = (hex) => {
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
        }
        throw new Error('Bad Hex');
    }

export function PrestazioneChart({data}) {
    let labels = data.map((record) => record['prestazione'])

    let color_repeats = Math.floor(data.length / colors.length)
    const makeRepeated = (arr, repeats) => [].concat(...Array.from({ length: repeats }, () => arr));
    let backgound_color_list = makeRepeated(colors, color_repeats)

    let remaining_colors = colors.slice(0, (data.length - (colors.length*color_repeats)))
    backgound_color_list = backgound_color_list.concat(remaining_colors)

    let datasets_ricavi = [{label: 'Ricavi in €', data: data.map((record) => record['value']), backgroundColor: backgound_color_list.map((color) => hexToRgb(color)), broderColor: backgound_color_list}]
    //, {label: 'Numero', data: data.map((record) => record['num']), backgroundColor: 'rgba(53, 162, 235, 0.5)'},]

    const processedData = {
        labels: labels,
        datasets: datasets_ricavi
    }

    return <Pie data={processedData} options={options} plugins={[ChartDataLabels]}/>;
}
